import React from 'react';
import { TechRadarComponent } from '@backstage-community/plugin-tech-radar';
import {
  Content,
  Header,
  Page,
  SupportButton,
  TabbedLayout,
} from '@backstage/core-components';
import Input, { InputProps } from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

const useStyles =
  makeStyles(theme => ({
    container: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
    },
  }));

interface TechRadarPanelProps {
  id: string;
}

export const TechRadarPanel: React.FC<TechRadarPanelProps> = (
  props: TechRadarPanelProps,
) => {
  const classes = useStyles();
  const [searchText, setSearchText] = React.useState('');

  const handleChange: InputProps["onChange"] = (e) => setSearchText(e.target.value)

  return (
    <Content noPadding>
      <Box className={classes.container}>
        <Input
          id="tech-radar-filter"
          type="search"
          placeholder="Filter"
          value={searchText}
          onChange={handleChange}
        />
        <SupportButton />
      </Box>
      <TechRadarComponent
        searchText={searchText}
        id={props.id}
        width={1500}
        height={800}
      />
    </Content>
  );
};

export const TechRadarPage: React.FC = () => {
  return (
    <Page themeId="tool">
      <Header title="Moonpig Tech Radar" />
      <TabbedLayout>
        <TabbedLayout.Route path="/" title="Backend">
          <TechRadarPanel id="backend" />
        </TabbedLayout.Route>
        <TabbedLayout.Route path="/web" title="Web">
          <TechRadarPanel id="web" />
        </TabbedLayout.Route>
        <TabbedLayout.Route path="/android" title="Android">
          <TechRadarPanel id="android" />
        </TabbedLayout.Route>
        <TabbedLayout.Route path="/ios" title="iOS">
          <TechRadarPanel id="ios" />
        </TabbedLayout.Route>
      </TabbedLayout>
    </Page>
  );
};

import { TechRadarApi } from '@backstage-community/plugin-tech-radar';
import {
  TechRadarLoaderResponse,
  MovedState,
} from '@backstage-community/plugin-tech-radar-common';
import { DiscoveryApi, IdentityApi } from '@backstage/core-plugin-api';
import { LadrTechRadar } from '@internal/plugin-techradar-backend';

export class MoonpigTechRadarApi implements TechRadarApi {
  private discoveryApi: DiscoveryApi;
  private identityApi: IdentityApi;

  constructor(options: { discovery: DiscoveryApi; identity: IdentityApi }) {
    this.discoveryApi = options.discovery;
    this.identityApi = options.identity;
  }

  private mapToTechRadarLoaderResponse(
    data: LadrTechRadar,
  ): TechRadarLoaderResponse {
    return {
      quadrants: data.quadrants.map(quadrant => ({
        ...quadrant,
        id: quadrant.id.toString(),
      })),
      rings: data.rings.map(ring => ({
        ...ring,
        id: ring.id.toString(),
      })),
      entries: data.entries.map(({ url, ...entry }) => ({
        ...entry,
        id: entry.id.toString(),
        quadrant: entry.quadrant.toString(),
        links: [
          {
            title: 'LADR Link',
            url,
          },
        ],
        timeline: entry.timeline.map(
          ({ moved, date, ringId, description }) => ({
            ringId: ringId.toString(),
            date: new Date(date),
            moved: moved as MovedState,
            description: description ?? undefined,
          }),
        ),
      })),
    };
  }

  async load(id: string | undefined): Promise<TechRadarLoaderResponse> {
    const url = await this.discoveryApi.getBaseUrl('techradar');
    const { token: idToken } = await this.identityApi.getCredentials();

    const response = await fetch(`${url}/${id}`, {
      method: 'GET',
      headers: {
        ...(idToken && { Authorization: `Bearer ${idToken}` }),
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch techradar');
    }

    return this.mapToTechRadarLoaderResponse(await response.json());
  }
}
